import styled from "styled-components";
import { Breakpoints } from "config-styles";

export const Term = styled.div`
  position: relative;
  display: flex;
  margin: 0 -15px;

  ${Breakpoints.desktopMax} {
    flex-direction: column;

    :not(:first-of-type):after {
      position: absolute;
      content: "";
      width: 90%;
      height: 1px;
      border-bottom: 1px solid #dedede;
      left: 50%;
      translate: -50%;
    }
  }

  & + & {
    margin-top: 15px;
  }

  p {
    font-size: 14px;
    line-height: 1.4em;
  }

  ul {
    list-style: disc;
    list-style-position: outside;
    margin-left: 15px;
    margin-top: 5px;
  }

  li {
    font-size: 14px;
    padding: 5px 0;
    line-height: 1.4em;
  }
`;

export const TermItem = styled.div`
  padding: 0 15px;
  flex: 1 0;

  ${Breakpoints.desktopMax} {
    margin-top: 15px;
  }
`;

export const TermTitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;
