import React from "react";
import Seo from "components/Seo";
import MainTitle from "components/MainTitle";
import LayoutTerms, { Content } from "components/LayoutTerms";
import { Term, TermItem, TermTitle } from "components/Term";

export default function PoliticaPrivacidade(props) {
  return (
    <LayoutTerms {...props}>
      <Seo
        title="Politica de Privacidade"
        description={`Politica de privacidade do Tecimob`}
      />
      <MainTitle
        titleTag="h1"
        title="Política de Privacidade"
        text={
          <>
            Esta é nossa política de privacidade.
            <br />É necessário que você leia, entenda e concorde.
          </>
        }
      />
      <Content>
        <Term>
          <TermItem>
            <p>
              O TECIMOB pode solicitar informações pessoais e dados através do
              preenchimento de formulários tais como: dados pessoais, perfis e
              contatos de clientes, imóveis (a venda ou locação) para
              determinadas ações previstas nesta Política de Privacidade e nos{" "}
              <a href="https://tecimob.com.br/termos-de-uso/" target="_blank">
                Termos de Uso
              </a>
              .
              <br />
              <br />
              O TECIMOB coletará, armazenará e processará algumas informações
              automaticamente, a cada navegação ou acesso do USUÁRIO no software
              ou em suas extensões como: data do acesso, características do
              dispositivo utilizado, do navegador, IP (com data e hora), origem
              do IP, informações sobre cliques, páginas acessadas, ou qualquer
              termo de procura digitado, interações com outros usuários, dentre
              outros.
              <br />
              <br />
              Não será realizada a venda, repasse ou distribuição das
              informações para outros órgãos ou empresas sem concordância do
              usuário (exceto em caso de fornecimento de serviços próprios ou
              relacionados assim como futuros serviços prestados pelo Tecimob),
              respeitando a LGPD (Lei Geral de Proteção de Dados) e as demais
              legislações vigentes.
              <br />
              <br />
              O USUÁRIO concorda e dá total consentimento de que os Dados e
              informações próprios e de terceiros cadastrados por ele captados e
              recebidos através do software ou de suas extensões serão
              utilizados internamente para análise de perfil e desempenho,
              desenvolvimento de novos recursos, melhorias, correções,
              cobranças, suporte, comercialização de produtos e envio de
              materiais relevantes ao mercado imobiliário e criação de
              estatísticas ou métricas.
              <br />
              <br />
              Os dados e informações de terceiros são de total responsabilidade
              do USUÁRIO, devendo o mesmo zelar pelo tratamento e segurança.
              <br />
              <br />
              O USUÁRIO pode solicitar a correção ou atualização dos dados e
              informações pessoais através do e-mail, onde serão processados
              imediatamente pelo TECIMOB.
              <br />
              <br />
              Ao excluir seu cadastro no TECIMOB, mediante solicitação do
              usuário ou findo o prazo da prestação do serviço, os dados do
              usuário serão excluídos automaticamente após os prazos estipulados
              nos termos de uso do Software, resguardados os prazos estipulados
              na legislação brasileira, bem como armazenamento de algumas
              informações que precisarão ser mantidas para os fins da prestação
              dos serviços do TECIMOB.
            </p>
          </TermItem>
        </Term>
      </Content>
    </LayoutTerms>
  );
}
