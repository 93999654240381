import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
  }
  
  #gatsby-focus-wrapper {
    overflow: visible;
  }
  
  html {
    background-color: #f1f4f9;
  }
`;

export const Wrapper = styled.div`
  margin-top: 25px;
  padding-bottom: 25px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
`;
