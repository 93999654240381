import React from "react";
import { Wrapper, Content, GlobalStyle } from "./styles";

function LayoutTerms({ children }) {
  return (
    <>
      <Wrapper>{children}</Wrapper>
      <GlobalStyle />
    </>
  );
}

export { Content };
export default LayoutTerms;
